<template>
    <!--三要素认证 -->
    <div class="auth-res">
        <div class="main">
            <div class="auth-success">
                <div class="img">
                    <img src="@/assets/yq/auth-success-02.png">
                </div>
                <div class="status-text">
                    <!-- <img src="@/assets/yq/auth-gz.png" > -->
                    <span>身份认证成功</span>
                </div>
                <div class="status-text-p">
                    您可以签署合同啦！
                </div>
                <div class="btn" @click="loadAgreements">
                    <zlButton text="我知道了" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import zlButton from "@/components/zlButton.vue";

import { setAuthInfo, setUserinfo } from "@/utils/auth";
import { getCheckStatus, getUserInfo } from "@/api/user";

export default {
    components: {
        zlButton,
    },
    data() {
        return {
            title: "身份认证",
            backVisible: false,
            authRes: true,
            buttonText: "重新认证",
            authType: '', //认证类型 1是三要素认证，2是人脸识别
            firstLoad: true,
        }
    },
    methods: {
        loadAgreements() {
            this.$store.dispatch("user/checkNimbleAgreements").then(res => {
                if (res) {
                    this.$router.replace({
                        path: "/index",
                    })
                    return;
                } else {
                    this.$router.replace({
                        path: "/agileAgreement"
                    });
                    return;
                }
            }).catch(err => {
                this.$toast({
                    message: err
                })
            })
        },
        authSuccess() {

            this.$router.replace({
                path: "/index",
            })
        },
    },
    created() {
        //更新信息
        getCheckStatus().then((res) => {
            let { status, data } = res;
            if (status) {
                setAuthInfo(JSON.stringify(data));
            }
        })
        getUserInfo().then((res) => {
            let { status, statusCode } = res;
            if (status) {
                setUserinfo(JSON.stringify(res.data));
            }
        });
    }
};
</script>

<style scoped>
div {
    box-sizing: border-box;
}
.auth-res .main {
    padding-top: 140px;
}
.auth-res .img {
    text-align: center;
}
.auth-res .img img {
    width: 177px;
    height: 103px;
}
.auth-res .auth-error .status-text {
    margin-top: 71px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-res .auth-error .status-text img {
    width: 19px;
    height: 19px;
}
.auth-res .auth-error .status-text span {
    margin-left: 5.5px;
    font-size: 18px;
    font-weight: 600;
}
.auth-res .auth-error .btn {
    width: 292px;
    margin: 64.5px auto;
}
.auth-res .auth-error .btn button {
    width: 292px;
    height: 48px;
    border-radius: 24px;
}

.auth-res .auth-error .btn .replace {
    background: #fff;
    color: #0471fd;
    border: 1px solid #0471fd;
    margin-top: 20px;
}
.auth-success .status-text {
    margin-top: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-success .status-text img {
    width: 19px;
    height: 19px;
}
.auth-success .status-text span {
    margin-left: 5.5px;
    font-size: 21px;
    font-weight: 600;
}
.auth-success .status-text-p {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b0b0b0;
}
.auth-success .btn {
    width: 292px;
    margin: 50px auto;
    margin-bottom: 0;
}
</style>
